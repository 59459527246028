


















import Vue from 'vue';

export default Vue.extend({
  name: 'connection-status',
  computed: {
    chipColor() {
      return this.$store.state.socket.isConnected ? 'green' : 'red';
    },
    isConnected() {
      return this.$store.state.socket.isConnected;
    },
    targetUrl() {
      return this.$store.state.socket.target;
    },
  },
});
