



















import Vue from 'vue';
import EegChart from '@/components/EegChart.vue';
import EegFilteredChart from '@/components/EegFilteredChart.vue';
import FftChart from '@/components/FftChart.vue';
import PowerBandsChart from '@/components/PowerBandsChart.vue';

export default Vue.extend({
  name: 'Home',
  components: {
    EegChart,
    EegFilteredChart,
    FftChart,
    PowerBandsChart,
  },
  data: () => ({}),
  computed: {
    filtersEnabled() {
      return this.$store.state.isFiltersEnabled ? 1 : 0;
    },
  },
  methods: {},
});
