<template>
  <div>
    <bar-chart
      :chart-data="datacollection"
      style="height: 270px; width: 530px; padding: 30px;"
    ></bar-chart>
  </div>
</template>

<script>
import BarChart from './BarChart';

export default {
  components: {
    BarChart,
  },
  computed: {
    datacollection() {
      const labels = [['Delta', '0.5-3 Hz'], ['Theta', '4-7 Hz'], ['Alpha', '8-12 Hz'], ['Beta', '13-31 Hz'], ['Gamma', '32-100 Hz']];
      const dataset = [{
        label: 'Band Power',
        fill: false,
        backgroundColor: ['rgba(255, 99, 132, 0.7)', 'rgba(255, 159, 64, 0.7)', 'rgba(255, 205, 86, 0.7)', 'rgba(75, 192, 192, 0.7)', 'rgba(54, 162, 235, 0.7)'],
        borderColor: ['rgb(255, 99, 132)', 'rgb(255, 159, 64)', 'rgb(255, 205, 86)', 'rgb(75, 192, 192)', 'rgb(54, 162, 235)'],
        borderWidth: 1,
        data: this.$store.state.bands,
      }];
      return {
        labels,
        datasets: dataset,
      };
    },
  },
};
</script>

<style>
  .small {
    max-width: 600px;
    margin:  150px auto;
  }
</style>
