<template>
<div>
  <div v-for="n in 8" :key="n" class="smoothie-container">
    <span class="uv-value">CH {{ n }}  </span>
    <canvas :id="'eeg-filtered-chart-canvas-' + n" width="480" height="70"/>
    <span class="uv-value">{{ current[n - 1] }}</span>
  </div>
</div>
</template>

<script>
import Vue from 'vue';
import { SmoothieChart, TimeSeries } from '@wofwca/smoothie';
import Fili from 'fili';

const smoothies = Array.from({ length: 8 }, () => new SmoothieChart({
  interpolation: 'linear', tooltip: true,
  // maxValue: 10000, minValue: -10000,
}));
const lines = Array.from({ length: 8 }, () => new TimeSeries());
lines.forEach((line, index) => smoothies[index].addTimeSeries(line, { lineWidth: 1, strokeStyle: '#00ff00' }));

export default Vue.extend({
  name: 'eeg-chart',
  data() { return { current: [0, 0, 0, 0, 0, 0, 0, 0] }; },
  computed: {
    chartsEnabled() {
      return this.$store.state.isChartsEnabled;
    },
  },
  mounted() {
    this.start();
    // 显示空图表，如果立即stop，背景是白的
    setTimeout(() => {
      if (!this.chartsEnabled) {
        this.stop();
      }
    }, 10);
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'enableChart') {
        if (mutation.payload === true) {
          this.start();
        } else {
          this.stop();
        }
      }
    });
  },
  beforeDestroy() {
    this.stop();
    this.unsubscribe();
  },
  methods: {
    start() {
      lines.forEach((line, index) => line.clear());
      smoothies.forEach((smoothie, index) => smoothie.streamTo(document.getElementById(`eeg-filtered-chart-canvas-${index + 1}`)));

      const arrays = Array.from({ length: 8 }, () => new Array(256).fill(0));

      this.$store.subscribe((mutation, state) => {
        if (!this.chartsEnabled) return;
        if (
          mutation.type === 'SOCKET_ONMESSAGE' && mutation.payload[0] === 'adcdata'
        ) {
          // [[ch1,ch2,ch3...ch8]*dataCount]
          const manyData = mutation.payload[1];
          const dataCount = manyData.length;
          manyData.forEach((channels) => {
            channels.forEach((channel, index) => {
              arrays[index].shift();
              arrays[index].push(channel);
            });
          });

          // [[1,2,3,4...dataCount]*8]
          const filtered = arrays.map((array) => {
            const a = this.bandpass(array);
            const b = this.notch(a);
            return b.slice(b.length - 1 - dataCount, b.length - 1);
          });

          filtered.forEach((array, channelIndex) => {
            array.forEach((data, index) => {
              lines[channelIndex].append(new Date().getTime() - dataCount + index, data);
              const formatted = data.toFixed(3);
              this.$set(this.current, channelIndex, formatted);
            });
          });
        }
      });
    },
    stop() {
      smoothies.forEach((smoothie) => smoothie.stop());
    },
    notch(signal) {
      const notchValue = 60;
      const iirCalculator = new Fili.CalcCascades();
      const notchFilterCoeffs = iirCalculator.bandstop({
        order: 2, // cascade 3 biquad filters (max: 12)
        characteristic: 'butterworth',
        Fs: 250, // sampling frequency
        Fc: notchValue,
        F1: notchValue - 1,
        F2: notchValue + 1,
        gain: 0, // gain for peak, lowshelf and highshelf
        preGain: false, // adds one constant multiplication for highpass and lowpass
        // k = (1 + cos(omega)) * 0.5 / k = 1 with preGain == false
      });

      const notchFilter = new Fili.IirFilter(notchFilterCoeffs);

      return notchFilter.multiStep(signal);
    },

    bandpass(signal) {
      const sampleRate = 250;
      const iirCalculator = new Fili.CalcCascades();

      const hpFilterCoeffs = iirCalculator.highpass({
        order: 3, // cascade 3 biquad filters (max: 12)
        characteristic: 'butterworth',
        Fs: sampleRate, // sampling frequency
        Fc: 1,
        gain: 0, // gain for peak, lowshelf and highshelf
        preGain: false, // adds one constant multiplication for highpass and lowpass
        // k = (1 + cos(omega)) * 0.5 / k = 1 with preGain == false
      });

      const hpFilter = new Fili.IirFilter(hpFilterCoeffs);
      const tmp = hpFilter.multiStep(signal);

      const lpFilterCoeffs = iirCalculator.lowpass({
        order: 3, // cascade 3 biquad filters (max: 12)
        characteristic: 'butterworth',
        Fs: sampleRate, // sampling frequency
        Fc: 50,
        gain: 0, // gain for peak, lowshelf and highshelf
        preGain: false, // adds one constant multiplication for highpass and lowpass
        // k = (1 + cos(omega)) * 0.5 / k = 1 with preGain == false
      });

      const lpFilter = new Fili.IirFilter(lpFilterCoeffs);
      return lpFilter.multiStep(tmp);
    },
  },
});
</script>

<style>
div.smoothie-container {
  display: flex;
  align-items: center;
  padding: 5px;
}
div.smoothie-chart-tooltip {
  background: #444;
  padding: 1em;
  margin-top: 20px;
  font-family: consolas;
  color: white;
  font-size: 10px;
  pointer-events: none;
}
span.uv-value {
  display: inline-block;
  padding: 10px;
  font-family: 'Consolas';
}
</style>
