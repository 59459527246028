










import Vue from 'vue';
import { SmoothieChart, TimeSeries } from '@wofwca/smoothie';

const smoothies = Array.from({ length: 8 }, () => new SmoothieChart({
  interpolation: 'linear', tooltip: true,
  // maxValue: 10000, minValue: -10000,
}));
const lines = Array.from({ length: 8 }, () => new TimeSeries());
lines.forEach((line: TimeSeries, index) => smoothies[index].addTimeSeries(line, { lineWidth: 1, strokeStyle: '#00ff00' }));

export default Vue.extend({
  name: 'eeg-chart',
  data() { return { current: [0, 0, 0, 0, 0, 0, 0, 0] }; },
  computed: {
    chartsEnabled() {
      return this.$store.state.isChartsEnabled;
    },
  },
  mounted() {
    this.start();
    // 显示空图表，如果立即stop，背景是白的
    setTimeout(() => {
      if (!this.chartsEnabled) {
        this.stop();
      }
    }, 10);
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'enableChart') {
        if (mutation.payload === true) {
          this.start();
        } else {
          this.stop();
        }
      }
    });
  },
  beforeDestroy() {
    this.stop();
    this.unsubscribe();
  },
  methods: {
    start() {
      lines.forEach((line: TimeSeries, index) => line.clear());
      smoothies.forEach((smoothie, index) => smoothie.streamTo(document.getElementById(`eeg-chart-canvas-${index + 1}`) as HTMLCanvasElement));

      this.$store.subscribe((mutation, state) => {
        if (!this.chartsEnabled) return;
        if (
          mutation.type === 'SOCKET_ONMESSAGE' && mutation.payload[0] === 'adcdata'
        ) {
          const manyData = mutation.payload[1];
          const dataCount = manyData.length;
          manyData.forEach((channels: Array<number>, dataIndex: number) => {
            lines.forEach((line: TimeSeries, index: number) => {
              line.append(new Date().getTime() - dataCount + dataIndex, channels[index]);
              const formatted = channels[index].toFixed(3);
              this.$set(this.current, index, formatted);
            });
          });
        }
      });
    },
    stop() {
      smoothies.forEach((smoothie) => smoothie.stop());
    },
    unsubscribe() {
      //
    },
  },
});
