<template>
  <v-app>
    <v-app-bar app color="primary" dark>
      <div class="d-flex align-center">
        <v-app-bar-nav-icon
          @click.stop="drawer = !drawer"
          v-if="$vuetify.breakpoint.mobile"
        ></v-app-bar-nav-icon>

        <v-toolbar-title> Dashboard </v-toolbar-title>
      </div>

      <v-spacer />

      <v-switch
      class="ma-4"
      v-model="enableFilter"
      inset
      hide-details
      label="滤波"
    ></v-switch>

      <save-button />
      <pause-resume-button />
      <server-switcher />
      <connection-status />
    </v-app-bar>

    <v-navigation-drawer
      app
      width="200"
      v-model="drawer"
      :permanent="!$vuetify.breakpoint.mobile"
      :temporary="$vuetify.breakpoint.mobile"
    >
      <v-list>
        <v-list-item class="px-2">
          <v-list-item-avatar>
            <v-img src="./assets/logo.png"></v-img>
          </v-list-item-avatar>
        </v-list-item>

        <v-list-item>
          <v-list-item-content>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-list-item-title class="title" v-bind="attrs" v-on="on">
                  EEG
                </v-list-item-title>
              </template>
              <span>0.1.0</span><br />
              <span>Build time: {{ buildDateTime }}</span>
            </v-tooltip>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>

      <v-list dense nav shaped>
        <v-list-item-group v-model="selectedItem" color="primary">
          <v-list-item
            v-for="item in items"
            :key="item.title"
            :to="item.path"
            link
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <v-container fluid>
        <router-view />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import Vue from 'vue';
import ConnectionStatus from '@/components/ConnectionStatus.vue';
import SaveButton from '@/components/SaveButton.vue';
import PauseResumeButton from '@/components/PauseResumeButton.vue';
import ServerSwitcher from '@/components/ServerSwitcher.vue';

export default Vue.extend({
  name: 'App',
  components: {
    ConnectionStatus, SaveButton, PauseResumeButton, ServerSwitcher,
  },
  data: () => ({
    drawer: false,
    selectedItem: 0,
    items: [
      {
        title: 'Dashboard',
        icon: 'mdi-view-dashboard',
        path: '/',
      },
      {
        title: 'Empty 1',
        icon: 'mdi-head-lightbulb-outline',
        path: '/about/',
      },
      {
        title: 'Empty 2',
        icon: 'mdi-brain',
        path: '/about/',
      },
    ],
    enableFilter: false,
  }),
  computed: {
    buildDateTime() {
      return document.documentElement.dataset.buildTimestampUtc;
    },
  },
  methods: {
  },
  watch: {
    enableFilter(newValue) {
      this.$store.commit('enableFilters', newValue);
    },
  },
});
</script>
