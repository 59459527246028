<template>
  <v-menu offset-y rounded="bg" transition="slide-y-transition">
    <template v-slot:activator="{ on, attrs }">
      <v-btn text v-bind="attrs" v-on="on">
        <v-icon left>mdi-broadcast</v-icon>
        服务器
        <v-icon right>mdi-chevron-down</v-icon>
      </v-btn>
    </template>

    <v-list>
      <v-list-item v-for="(item, index) in items" :key="index" link @click="switchAddress(item)">
        <v-list-item-title>{{ item }}</v-list-item-title>
         <v-list-item-action>
          <v-btn icon @click.stop="removeAddress(item)">
            <v-icon color="lighten-1">mdi-delete</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>

      <v-divider />

      <v-dialog v-model="dialog" persistent max-width="500px">
        <template v-slot:activator="{ on, attrs }">
          <v-list-item link v-bind="attrs" v-on="on">
            <v-list-item-title>添加</v-list-item-title>
          </v-list-item>
        </template>
        <v-card>
          <v-card-title>
            <span class="headline">添加服务器</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    v-model="newServerAddress"
                    :rules="rules.address"
                    label="服务器地址"
                    hint="ws://"
                    required
                    outlined
                    clearable
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialog = false">
              取消
            </v-btn>
            <v-btn color="blue darken-1" text @click="save">
              保存
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-list>
  </v-menu>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  name: 'server-switcher',
  data() {
    return {
      items: [],
      dialog: false,
      newServerAddress: '',
      rules: {
        address: [
          (val) => ((val || '').length > 0 && val.startsWith('ws://')) || '无效的地址',
        ],
      },
    };
  },
  mounted() {
    if (localStorage.servers) {
      this.items = JSON.parse(localStorage.servers);
    }
  },
  computed: {
    targetUrl() {
      return this.$store.state.socket.target;
    },
  },
  methods: {
    switchAddress(address) {
      localStorage.defaultServer = address;
      window.location.reload();
    },
    removeAddress(address) {
      if (this.items.indexOf(address) !== -1) this.items.splice(this.items.indexOf(address), 1);
      localStorage.servers = JSON.stringify(this.items);
    },
    isAddressValid() {
      return this.newServerAddress.length > 0 && this.newServerAddress.startsWith('ws://');
    },
    save() {
      if (!this.addressIsValid()) return;
      this.items.push(this.newServerAddress);
      localStorage.servers = JSON.stringify(this.items);
      this.newServerAddress = '';
      this.dialog = false;
    },
  },
});
</script>
