<template>
    <v-btn text @click="save">
      <v-icon left>mdi-content-save</v-icon>
      保存
    </v-btn>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  name: 'save-button',
  computed: {
    chartsEnabled() {
      return this.$store.state.isChartsEnabled;
    },
  },
  mounted() {
    this.arrays = Array(16384).fill([]);

    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (!this.chartsEnabled) return;
      if (
        mutation.type === 'SOCKET_ONMESSAGE' && mutation.payload[0] === 'adcdata'
      ) {
        const manydata = mutation.payload[1];
        manydata.forEach((channels) => {
          this.arrays.shift();
          this.arrays.push(channels);
        });
      }
    });
  },
  beforeDestroy() {
    this.unsubscribe();
  },
  methods: {
    save() {
      const filtered = this.arrays.filter((channels) => channels.length > 0);
      const data = filtered.map((channels) => channels.join(',')).join('\n');
      const filename = 'eeg.txt';
      const blob = new Blob([data], { type: 'text/csv' });

      const elem = window.document.createElement('a');
      elem.href = window.URL.createObjectURL(blob);
      elem.download = filename;
      document.body.appendChild(elem);
      elem.click();
      document.body.removeChild(elem);
    },
  },

});
</script>
