<template>
  <div>
    <v-btn text v-if="chartsEnabled" @click="disableCharts">
      <v-icon>mdi-pause</v-icon>
      暂停
    </v-btn>
    <v-btn text v-if="!chartsEnabled" @click="enableCharts">
      <v-icon>mdi-play</v-icon>
      开始
    </v-btn>
  </div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  name: 'pause-resume-buttons',
  computed: {
    chartsEnabled() {
      return this.$store.state.isChartsEnabled;
    },
  },
  methods: {
    enableCharts() {
      this.$store.commit('enableChart', true);
    },
    disableCharts() {
      this.$store.commit('enableChart', false);
    },
  },
});
</script>
