import Vue from 'vue';
import VueNativeSock from './vendor/vue-native-websocket';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';

Vue.config.productionTip = false;

if (!localStorage.defaultServer) {
  localStorage.defaultServer = 'ws://localhost:9090';
  localStorage.servers = JSON.stringify(['ws://localhost:9090', 'ws://192.168.31.240']);
}
const server = localStorage.defaultServer;

Vue.use(VueNativeSock, server, {
  store,
  format: 'json',
});

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
