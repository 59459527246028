<template>
    <UplotVue
        style="min-height: 370px;"
        :data="datacollection"
        :options="options"
        :target="target"
    />
</template>

<script>
import UplotVue from 'uplot-vue';
import 'uplot/dist/uPlot.min.css';
import { FFT } from 'dsp.js';

export default {
  components: { UplotVue },
  data() {
    return {
      options: {
        title: 'FFT (256)',
        width: 540,
        height: 240,
        series: [{
          label: 'Freq',
        }, {
          label: 'CH 1', // (uV²/Hz)
          points: { show: false },
          stroke: 'blue',
        }, {
          label: 'CH 2',
          points: { show: false },
          stroke: 'green',
        }, {
          label: 'CH 3',
          points: { show: false },
          stroke: 'yellow',
        }, {
          label: 'CH 4',
          points: { show: false },
          stroke: 'purple',
        }, {
          label: 'CH 5',
          points: { show: false },
          stroke: 'pink',
        }, {
          label: 'CH 6',
          points: { show: false },
          stroke: 'red',
        }, {
          label: 'CH 7',
          points: { show: false },
          stroke: 'brown',
        }, {
          label: 'CH 8',
          points: { show: false },
          stroke: 'grey',
        }],
        scales: {
          x: { time: false },
          y: {
            distr: 3,
            log: 10,
          },
        },
      },
      target: null,
    };
  },
  computed: {
    chartsEnabled() {
      return this.$store.state.isChartsEnabled;
    },
  },
  beforeMount() {
    // Initialize data inside mounted hook, to prevent Vue from adding watchers,
    // otherwise performance becomes unbearable
    this.datacollection = [];
  },
  mounted() {
    const arrays = Array.from({ length: 8 }, () => new Array(256).fill(0));
    const bufferSize = 256;
    const samplingRate = 1024;
    const resultSize = bufferSize / 2;
    const labels = Array.from({ length: resultSize }, (_, i) => i);

    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (!this.chartsEnabled) return;
      if (
        mutation.type === 'SOCKET_ONMESSAGE' && mutation.payload[0] === 'adcdata'
      ) {
        const manydata = mutation.payload[1];
        manydata.forEach((channels) => {
          channels.forEach((channel, index) => {
            arrays[index].shift();
            arrays[index].push(channel);
          });
        });
      }
    });

    const ffts = Array.from({ length: 8 }, () => new FFT(bufferSize, samplingRate));
    this.fftInterval = setInterval(() => {
      if (!this.chartsEnabled) return;
      const results = Array.from({ length: 8 }, (_, i) => {
        ffts[i].forward(arrays[i]);
        return Array.from(ffts[i].spectrum);
      });

      this.datacollection = [labels, ...results];
      this.$forceUpdate();

      this.powerBands(results);
    }, 100);
  },
  methods: {
    sum(array) {
      let num = 0;
      for (let i = 0, l = array.length; i < l; i += 1) {
        num += array[i];
      }
      return num;
    },
    powerBands(fftResults) {
      const FREQUENCY_BANDS = {
        delta: [0.5, 4],
        theta: [4, 8],
        alpha: [8, 13],
        beta: [13, 32],
        gamma: [32, 100],
      };
      const bands = Object.keys(FREQUENCY_BANDS).map((key) => {
        const min = FREQUENCY_BANDS[key][0];
        const max = FREQUENCY_BANDS[key][1];
        const channelAverages = fftResults.map((channel) => {
          const filtered = channel.filter(
            (spectrum, index) => index >= min && index <= max,
          );
          const average = this.sum(filtered) / filtered.length;
          return average;
        });
        const globalAverages = this.sum(channelAverages) / channelAverages.length;
        return globalAverages;
      });
      this.$store.commit('updateBands', bands);
      return bands;
    },
  },
  beforeDestroy() {
    this.unsubscribe();
  },
  destroy() {
    clearInterval(this.fftInterval);
  },
};
</script>

<style>
div.uplot {
  font-family: inherit !important;
}
</style>
